import axios from "axios";
import qs from "qs";
import store from "../store";

// 请求路径
let url = "";
url = window.location.origin;
 
// url = "https://pc2erttroqwdw.da16888.top";  
// url = "https://bei1pcwoecs.hh36518.com";
 
 

const service = axios.create({
  baseURL: url,
  timeout: 30000,
});
// buhuotype

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求前做些什么  是否需要token，config是请求配置
  
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded;charset=utf-8";
    config.data = qs.stringify(config.data);
    return config;
  },
  (error) => {
    //  请求失败做些什么

    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么

    if (response.data.Msg == "账户未登陆") {
      // window.location.reload();
      store.commit("setUserInfo", {});
      store.commit("resultGoBool", false);
      window.location.href = "/";
    }
    return response.data;
  },
  (err) => {
    // 响应失败的时候做点什么  比如token失效？

    return Promise.reject(err);
  }
);
export default service;
